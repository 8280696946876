import dayjs from "dayjs";
import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import localizedFormat from "dayjs/plugin/localizedFormat";
import toast from "react-hot-toast";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(date: Date | string, format: string) {
  dayjs.extend(localizedFormat);
  return dayjs(date).format(format);
}

export function pushNotification(
  message: string,
  handleClick?: () => void,
  options?: NotificationOptions,
) {
  if (!("Notification" in window)) {
    toast.error("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    const notification = new Notification(message, options);
    notification.addEventListener(
      "click",
      handleClick ? handleClick : () => {},
    );
    setTimeout(notification.close.bind(notification), 10000);
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const notification = new Notification(message, options);
        notification.addEventListener(
          "click",
          handleClick ? handleClick : () => {},
        );
        setTimeout(notification.close.bind(notification), 4000);
      }
    });
  }
}
