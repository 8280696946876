import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import ProtectedRoute from "../components/ProtectedRoute";
import Dashboard from "./dashboard";
import ErrorPage from "./errorpage";
import Login from "./login";
import Appointments from "./appointments";
import Services from "./services";
import Admins from "./admins";
import CreateService from "./create-service";
import AppointmentDetail from "./appointment-detail";
import CreateAdmin from "./create-admin";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <Dashboard /> },
      { path: "/appointments", element: <Appointments /> },
      { path: "/appointment-detail/:id", element: <AppointmentDetail /> },
      { path: "/services", element: <Services /> },
      { path: "/services/create", element: <CreateService /> },
      { path: "/services/edit/:id", element: <CreateService edit={true} /> },
      { path: "/admins", element: <Admins /> },
      { path: "/admins/create", element: <CreateAdmin /> },
      { path: "/admins/edit/:id", element: <CreateAdmin edit={true} /> },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
