import { Link } from "react-router-dom";
import NotFoundImage from "../assets/images/404.png";
import Button from "../components/ui/Button";
import Heading from "../components/ui/Heading";
import Text from "../components/ui/Text";

export default function ErrorPage() {
  return (
    <div className="flex items-center justify-center h-screen space-x-10">
      <img src={NotFoundImage} alt="not-found" />
      <div>
        <Heading clr="primary">Uh oh...</Heading>
        <Heading size="lg">Something went wrong</Heading>
        <Text clr="light">
          Looks like this page doesn't exist or was removed.
        </Text>
        <Link to="/">
          <Button className="mt-3">Back to Dashboard</Button>
        </Link>
      </div>
    </div>
  );
}
