import { CalendarMonthOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { handleError } from "../api/error-handler";
import {
  getAppointments,
  updateAppointmentStatus,
} from "../api/requests/appointment";
import Button from "../components/ui/Button";
import Heading from "../components/ui/Heading";
import Popup from "../components/ui/Popup";
import Text from "../components/ui/Text";
import { formatDate } from "../lib/utils";
import { AppointmentResponse } from "../types/appointment.types";
import { AppointmentStatus, PageType } from "../types/enum";

const AppointmentDetail = () => {
  const { id } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);

  const searchCriteriaList = [
    {
      filterKey: "id",
      operation: "eq",
      value: id,
    },
  ];

  const { data, error, isLoading, refetch } = useQuery<AppointmentResponse>({
    queryFn: () => getAppointments(0, PageType.unPaged, searchCriteriaList),
    queryKey: ["getAppointment"],
  });

  const {
    mutate,
    isPending: isUpdating,
    error: errorUpdate,
  } = useMutation<AppointmentResponse, unknown, { id: string; status: number }>(
    {
      mutationFn: (data) => updateAppointmentStatus(data),
      onSuccess: () => {
        toast.success("Appointment Updated");
        refetch();
      },
      onSettled: () => {
        setShowPopup(false);
      },
    },
  );

  if (error) {
    handleError(error);
  }
  if (errorUpdate) {
    handleError(errorUpdate);
  }

  const handleUpdateStatus = (status: number, title: string) => {
    setSelectedStatus(status);
    setTitle(title);
    setShowPopup(true);
  };

  const updateStatus = (status: number) => {
    mutate({
      id: id!,
      status,
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full flex items-center justify-center text-primary h-[calc(100vh-100px)]">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <div className="flex items-center space-x-2 mb-5">
            <CalendarMonthOutlined className="text-primary" />
            <Heading size="md">Appointment Detail</Heading>
          </div>
          <div className="flex flex-col space-y-5 max-w-lg mx-auto">
            <div className="shadow rounded p-4">
              <Heading className="mb-2" clr="primary">
                User Information
              </Heading>
              <div className="flex flex-col space-y-2 divide-y divide-neutral-300">
                <div className="flex items-center justify-between">
                  <Text>Full Name</Text>
                  <Text>{data?.appointments[0].fullName}</Text>
                </div>
                <div className="flex items-center justify-between pt-2">
                  <Text>Phone number</Text>
                  <Text>{data?.appointments[0].phoneNumber}</Text>
                </div>
                <div className="flex items-center justify-between pt-2">
                  <Text>Alternative phone number</Text>
                  <Text>{data?.appointments[0].alternativePhoneNumber}</Text>
                </div>
              </div>
            </div>
            <div className="shadow rounded p-4">
              <Heading className="mb-2" clr="primary">
                Service
              </Heading>
              <div className="flex flex-col space-y-2 divide-y divide-neutral-300">
                <Text>{data?.appointments[0].serviceCategory.title}</Text>
                <div className="flex flex-col pt-2">
                  {data?.appointments[0].serviceCategory.services.map(
                    (service, i) => (
                      <Text key={service.id}>
                        {i + 1}. {service.title}
                      </Text>
                    ),
                  )}
                </div>
              </div>
            </div>
            <div className="shadow rounded p-4">
              <Heading className="mb-2" clr="primary">
                Appointment
              </Heading>
              <div className="flex items-center justify-between">
                <Text>Appointment Date</Text>
                <Text>
                  {formatDate(data?.appointments[0].appointmentDate!, "LLL")}
                </Text>
              </div>
              <div className="flex items-center justify-between">
                <Text>Status</Text>
                <Text>{data?.appointments[0].status}</Text>
              </div>
              <div className="flex items-center space-x-2 justify-end mt-5">
                <Button
                  onClick={() =>
                    handleUpdateStatus(AppointmentStatus.ACTIVE, "Accept")
                  }
                >
                  Accept
                </Button>
                <Button
                  onClick={() =>
                    handleUpdateStatus(AppointmentStatus.DECLINE, "Decline")
                  }
                  variant="outline"
                >
                  Decline
                </Button>
              </div>
            </div>
          </div>
          <Popup
            header={`${title} Appointment`}
            body={
              <Text clr="light">
                Are you sure you want to{" "}
                <span className="lowercase">{title}</span> this appointment?
              </Text>
            }
            show={showPopup}
            onClose={() => setShowPopup(false)}
            footer={
              <div className="flex justify-center gap-4">
                <Button
                  isLoading={isUpdating}
                  variant="default"
                  onClick={() => updateStatus(selectedStatus!)}
                >
                  Yes, I'm sure
                </Button>
                <Button variant="outline" onClick={() => setShowPopup(false)}>
                  No, cancel
                </Button>
              </div>
            }
          />
        </>
      )}
    </>
  );
};

export default AppointmentDetail;
