export const endpoints = {
  login: "auth/admin/login",

  createServiceCategory: "service-category/save",
  updateServiceCategory: "service-category/update",
  updateServiceStatus: "service/update-status",
  getServiceCategories: "service-category/list",
  getServiceCategory: "service-category",

  getAppointments: "appointment/list",
  updateAppointmentStatus: "appointment/update-status",
  getStat: "appointment/stat",

  createAdmin: "auth/admin/register",
  updateAdmin: "auth/admin/update",
  getAdmins: "user/list",
};
