import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import type { FC, InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean;
  handleSearch: () => void;
}

const SearchInput: FC<InputProps> = ({
  fullWidth,
  handleSearch,
  name,
  className,
  ...props
}) => {
  return (
    <div className={`${fullWidth && "w-full flex-1 "} relative`}>
      <input
        className={`${className} bg-gray border border-gray focus:outline-none focus:ring-2 focus:ring-offset-2  p-2 rounded-md !w-full !text-md font-normal`}
        {...props}
      />
      <IconButton onClick={handleSearch} className="!absolute right-0">
        <Search className="w-6 h-6 cursor-pointer" />
      </IconButton>
    </div>
  );
};

export default SearchInput;
