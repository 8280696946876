import Cookies from "js-cookie";
import { useState, useEffect, FC } from "react";
import { Navigate } from "react-router-dom";
import { ACCESS_TOKEN } from "../api/constants";
import { CircularProgress } from "@mui/material";

interface ProtectedRouteProps {
  children: JSX.Element;
}
const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);

  useEffect(() => {
    auth();
  }, []);

  const auth = () => {
    const token = Cookies.get(ACCESS_TOKEN);
    if (!token) {
      setIsAuthorized(false);
      return;
    }
    setIsAuthorized(true);
  };

  if (isAuthorized === null)
    return (
      <div className="grid place-items-center h-screen text-primary">
        <CircularProgress color="inherit" />
      </div>
    );

  return isAuthorized ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
