import { forwardRef, ReactNode } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { HTMLAttributes } from "react";
import { cn } from "../../lib/utils";

const textVariants = cva(
  "text-left text-base font-normal leading-normal tracking-normal",
  {
    variants: {
      clr: {
        default: "text-neutral-900",
        normal: "text-neutral-600",
        light: "text-neutral-500",
      },
    },
    defaultVariants: {
      clr: "default",
    },
  },
);

interface TextProps
  extends HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof textVariants> {
  children: ReactNode;
}

const Text = forwardRef<HTMLParagraphElement, TextProps>(
  ({ clr, children, className, ...props }, ref) => {
    return (
      <p ref={ref} {...props} className={cn(textVariants({ clr, className }))}>
        {children}
      </p>
    );
  },
);

Text.displayName = "Text";

export default Text;
