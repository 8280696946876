import { Close } from "@mui/icons-material";
import { FC } from "react";

interface PopupProps {
  header?: React.JSX.Element | string;
  body: React.JSX.Element;
  footer: React.JSX.Element;
  show: boolean;
  onClose: () => void;
}

const Popup: FC<PopupProps> = ({ header, body, footer, show, onClose }) => {
  return (
    <div
      className={`fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full ${
        show ? "block" : "hidden"
      }`}
    >
      <div
        onClick={onClose}
        className={
          "fixed top-0 left-0 right-0 w-full overflow-x-hidden overflow-y-auto md:inset-0 bg-black/25 min-h-full"
        }
      ></div>
      <div className="relative w-full max-w-lg max-h-full z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="relative bg-white rounded-lg shadow">
          {header ? (
            <div className="flex items-center justify-between p-4 md:p-5 border-b border-gray rounded-t">
              <h3 className="text-xl font-medium">{header}</h3>
              <button
                type="button"
                className="text-neutral-500 bg-transparent hover:bg-gray rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              >
                <Close onClick={onClose} />
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-between p-4">
              <button
                type="button"
                className="text-neutral-500 bg-transparent hover:bg-gray rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              >
                <Close onClick={onClose} />
              </button>
            </div>
          )}
          <div className="p-4 md:p-5 space-y-4">{body}</div>
          <div className="flex items-center p-4 md:p-5 border-t border-gray rounded-b">
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
