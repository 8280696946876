import Logo from "../assets/images/logo.png";
import {
  DashboardOutlined,
  CalendarMonthOutlined,
  MedicalServicesOutlined,
  AdminPanelSettingsOutlined,
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import Text from "./ui/Text";
import { Link } from "react-router-dom";

interface SidebarItem {
  icon: any;
  label: string;
  link: string;
}
export const sidebarItems: SidebarItem[] = [
  { icon: DashboardOutlined, label: "Dashboard", link: "/" },
  {
    icon: CalendarMonthOutlined,
    label: "Appointments",
    link: "/appointments",
  },
  {
    icon: MedicalServicesOutlined,
    label: "Services",
    link: "/services",
  },
  {
    icon: AdminPanelSettingsOutlined,
    label: "Admins",
    link: "/admins",
  },
];

const Sidebar = () => {
  return (
    <div className="fixed top-0 bottom-0 h-screen bg-white drop-shadow-md min-w-64 p-4">
      <Link to="/" className="flex items-center space-x-2">
        <img src={Logo} alt="logo" className="w-10 h-10 aspect-video" />
        <Text className="text-inherit">Lebeza</Text>
      </Link>
      <div className="mt-6">
        {sidebarItems.map(({ icon: Icon, link, label }) => (
          <NavLink
            key={link}
            to={link}
            className={({ isActive }) =>
              `${
                isActive ? "bg-primary text-white" : ""
              } block py-2 px-3 rounded-md mb-4 text-neutral-500`
            }
          >
            <div className="flex items-center space-x-2">
              <Icon className="text-inherit" />
              <Text className="text-inherit">{label}</Text>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
