import api from "..";
import { AdminsResponse } from "../../types/user.types";
import { CreateAdminFormInputs } from "../../validations/CreateAdminSchema";
import { endpoints } from "../endpoints";

const createAdmin = async (
  data: CreateAdminFormInputs,
): Promise<AdminsResponse> => {
  const response = await api.post(endpoints.createAdmin, {
    ...data,
    status: parseInt(data.status),
  });
  return response.data;
};

const updateAdmin = async (
  id: string,
  data: CreateAdminFormInputs,
): Promise<AdminsResponse> => {
  const response = await api.post(`${endpoints.updateAdmin}/${id}`, {
    ...data,
    status: parseInt(data.status),
  });
  return response.data;
};

const getAdmins = async (
  page: number,
  pagingType: string,
  searchCriteriaList: any,
): Promise<AdminsResponse> => {
  const response = await api.post(endpoints.getAdmins, {
    dataOption: "all",
    searchCriteriaList,
    page,
    pagingType,
  });
  return response.data;
};

export { createAdmin, updateAdmin, getAdmins };
