//@ts-nocheck
import Logo from "../assets/images/logo.png";
import LoginMockUp from "../assets/images/login-mockup.png";
import Button from "../components/ui/Button";
import Heading from "../components/ui/Heading";
import Input from "../components/ui/Input";
import Cookies from "js-cookie";
import { ACCESS_TOKEN } from "../api/constants";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoginFormInputs, LoginSchema } from "../validations/LoginSchema";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { login } from "../api/requests/auth";
import toast from "react-hot-toast";
import { UserResponse } from "../types/user.types";
import { handleError } from "../api/error-handler";

const Login = () => {
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation<UserResponse>({
    mutationFn: (data) => login(data),
    onSuccess: (data) => {
      Cookies.set(ACCESS_TOKEN, data.token, {
        secure: true,
      });
      navigate("/");
      toast.success("Logged in successfully");
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: zodResolver(LoginSchema),
  });

  const handleLogin: SubmitHandler<LoginFormInputs> = (data) => {
    mutate(data);
  };

  useEffect(() => {
    const token = Cookies.get(ACCESS_TOKEN);
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="flex items-center justify-between h-screen">
      <form
        onSubmit={handleSubmit(handleLogin)}
        className="basis-1/2 max-w-96 mx-auto"
      >
        <img
          src={Logo}
          alt="logo"
          className="w-10 h-10 aspect-video mb-5 mx-auto"
        />
       
        <Heading size="lg" className="text-center my-2">
          <span className="text-primary">Lebeza</span> Admin
        </Heading>
        <Input
          register={register}
          name="email"
          type="email"
          label="Email"
          placeholder="example@gmail.com"
          error={errors.email?.message}
        />
        <Input
          register={register}
          name="password"
          type="password"
          label="Password"
          placeholder="Enter at least 6 characters"
          error={errors.password?.message}
        />
        <Button
          isLoading={isPending}
          type="submit"
          size="lg"
          className="w-full mt-5"
        >
          Submit
        </Button>
      </form>
      <div className="basis-1/2 bg-gray h-full flex items-center justify-center">
        <img src={LoginMockUp} alt="mockup" className="" />
      </div>
    </div>
  );
};

export default Login;
