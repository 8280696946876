import { FC } from "react";
import Heading from "./Heading";
import { CircularProgress } from "@mui/material";

interface CardProps {
  title: string;
  body: string | number;
  icon: any;
  bg: string;
  loading: boolean;
}

const Card: FC<CardProps> = ({ title, body, icon: Icon, bg, loading }) => {
  return (
    <div
      className={`${bg} p-4 shadow rounded-sm flex flex-1 justify-between items-start`}
    >
      <div>
        <Heading>{title}</Heading>
        {loading ? (
          <CircularProgress size={15} color="inherit" />
        ) : (
          <Heading size="lg">{body}</Heading>
        )}
      </div>
      <div className="border border-primary p-2 shadow rounded">
        <Icon className=" text-primary" />
      </div>
    </div>
  );
};

export default Card;
