import { z } from "zod";

export const CreateServiceSchema = z
  .object({
    isImageRequired: z.boolean(),
    title: z
      .string({
        required_error: "* Required",
      })
      .min(1, "* Required"),
    description: z
      .string({
        required_error: "* Required",
      })
      .min(1, "* Required"),
    image: z.any().optional(),
  })
  .refine(
    (schema) => {
      if (schema.isImageRequired && schema.image.length === 0) {
        return false;
      }
      return true;
    },
    { message: "* Required" },
  );

export type CreateServiceFormInputs = z.infer<typeof CreateServiceSchema>;
