import { endpoints } from "../endpoints";
import api from "..";
import { LoginFormInputs } from "../../validations/LoginSchema";
import { UserResponse } from "../../types/user.types";

const login = async (data: LoginFormInputs): Promise<UserResponse> => {
  const response = await api.post(endpoints.login, data);
  return response.data;
};

export { login };
