export enum PageType {
  paged = "PAGED",
  unPaged = "PAGED",
}

export enum AppointmentStatus {
  ACTIVE = 1,
  PENDING = 2,
  INACTIVE = 3,
  DELETED = 4,
  DECLINE = 5,
}

export enum AdminStatus {
  "ACTIVE" = 1,
  "PENDING" = 2,
  "INACTIVE" = 3,
  "DELETED" = 4,
  "DECLINE" = 5,
}
