import type { FC, SelectHTMLAttributes } from "react";
import { Fragment } from "react";
import type { UseFormRegister } from "react-hook-form";

type SelectOption = {
  label: string;
  value: string;
};

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  disabled?: boolean;
  options: SelectOption[];
  value?: string;
  register?: UseFormRegister<any>;
  label?: string | undefined;
  error?: string | undefined;
  fullWidth?: boolean;
  endAdornment?: React.ReactNode;
  placeholder?: string;
}

const Select: FC<SelectProps> = ({
  register,
  name,
  disabled,
  label,
  value,
  options,
  error,
  placeholder,
  className,
  fullWidth,
  style,
  endAdornment,
  ...props
}) => {
  return (
    <>
      <div style={style} className={`${fullWidth && "w-full"} mb-3 relative`}>
        <label className="block mb-1" htmlFor={name}>
          {label}
        </label>
        <select
          className={`${className} bg-gray border border-gray focus:outline-none focus:ring-2 focus:ring-offset-2  p-3 rounded-md !w-full !text-md font-normal`}
          {...(register ? register(name!) : null)}
          {...props}
          value={value}
          disabled={disabled}
        >
          {options.map(({ value, label }) => (
            <Fragment key={value}>
              {placeholder && (
                <option value="" disabled selected hidden>
                  {placeholder}
                </option>
              )}
              <option value={value}>{label}</option>
            </Fragment>
          ))}
        </select>
        {error && <span className="text-sm text-red-500">{error}</span>}
      </div>
      {endAdornment && endAdornment}
    </>
  );
};

export default Select;
