// @ts-nocheck
import { zodResolver } from "@hookform/resolvers/zod";
import { Add, DeleteForever, Edit } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { handleError } from "../api/error-handler";
import {
  createServiceCategory,
  getServiceCategory,
  updateServiceCategory,
  updateServiceStatus,
} from "../api/requests/service";
import Button from "../components/ui/Button";
import Heading from "../components/ui/Heading";
import Input from "../components/ui/Input";
import Text from "../components/ui/Text";
import Popup from "../components/ui/Popup";
import CircularWithValueLabel from "../components/ui/UploadProgress";
import { ServiceCategoryResponse } from "../types/service.types";
import {
  CreateServiceFormInputs,
  CreateServiceSchema,
} from "../validations/CreateServiceSchema";
import { CircularProgress } from "@mui/material";
import Textarea from "../components/ui/Textarea";

interface CreateServiceProps {
  edit?: boolean;
}

const CreateService: FC<CreateServiceProps> = ({ edit }) => {
  const { id } = useParams();
  const [services, setServices] = useState<
    { name: string; value: string; id: string | null }[]
  >([]);
  const [progressBarValue, setProgressBarValue] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string>("");
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [deletedService, setDeletedService] = useState<{
    name: string;
    value: string;
    id: string | null;
  } | null>(null);

  const isEdit = id && edit;

  const {
    data: serviceData,
    error,
    isLoading,
  } = useQuery<ServiceCategoryResponse>({
    queryFn: () => getServiceCategory(id!),
    queryKey: ["getServiceCategory"],
    enabled: isEdit ? true : false,
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateServiceFormInputs>({
    defaultValues: {
      isImageRequired: false,
    },
    resolver: zodResolver(CreateServiceSchema),
  });

  const file = watch("image");

  const { mutate, isPending } = useMutation<ServiceCategoryResponse>({
    mutationFn: (data) =>
      isEdit ? updateServiceCategory(id!, data) : createServiceCategory(data),
    onSuccess: (data) => {
      toast.success(`Service ${isEdit ? "updated" : "created"}`);
      setServices([]);
      reset();
      setProgressBarValue(0);
      setImagePreview("");
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const { mutate: mutateServiceDelete, isPending: isServiceDeletePending } =
    useMutation<{
      services: {
        title: string;
        value: string;
        id: string;
      }[];
    }>({
      mutationFn: (data) => updateServiceStatus(data),
      onSuccess: (data) => {
        setServices((prev) =>
          prev.filter(
            (prevService) => prevService.name !== data.services[0].title,
          ),
        );
        setShowPopup(false);
      },
      onError: (error) => {
        handleError(error);
      },
    });

  useEffect(() => {
    if (serviceData) {
      setValue("title", serviceData.serviceCategories[0].title);
      setValue("description", serviceData.serviceCategories[0].description);
      setImagePreview(serviceData.serviceCategories[0].imageUrl);
      setServices(
        serviceData.serviceCategories[0].services.map((service, i) => ({
          id: service.id,
          name: `Service ${i + 1}`,
          value: service.title,
        })),
      );
    }
  }, [serviceData, setValue]);

  useEffect(() => {
    if (imagePreview) {
      setValue("isImageRequired", false);
    } else {
      setValue("isImageRequired", true);
    }
    if (!file || file.length === 0) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setImagePreview(fileReader.result);
    };
    fileReader.readAsDataURL(file[0]);
  }, [file, imagePreview, setValue]);

  useEffect(() => {
    if (!isEdit) {
      setServices([]);
      reset();
      setProgressBarValue(0);
      setImagePreview("");
    }
  }, [isEdit, reset]);

  const handleCreateService: SubmitHandler<CreateServiceFormInputs> = async (
    data,
  ) => {
    // console.log({
    //   title: data.title,
    //   imageUrl: imagePreview,
    //   services: services.map((service) => ({
    //     id: service.id ? service.id : null,
    //     title: service.value,
    //   })),
    // });
    if (imagePreview === serviceData?.serviceCategories[0].imageUrl) {
      mutate({
        title: data.title,
        description: data.description,
        imageUrl: imagePreview,
        services: services.map((service) => ({
          id: service.id ? service.id : null,
          title: service.value,
        })),
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", data.image[0]);
    formData.append("folder", "lebeza");
    formData.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
    );
    try {
      setIsUploading(true);
      const { data: cldData } = await axios.post(
        process.env.REACT_APP_CLOUDINARY_API,
        formData,
        {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            setProgressBarValue(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
            );
          },
        },
      );
      mutate({
        title: data.title,
        description: data.description,
        imageUrl: cldData.secure_url,
        services: services.map((service) => ({
          id: service.id ? service.id : null,
          title: service.value,
        })),
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleAddService = () => {
    let totalServices = services.length;
    totalServices += 1;
    setServices((prev) => [
      ...prev,
      { name: `Service ${totalServices}`, value: "", id: null },
    ]);
  };

  const handleRemoveService = (service: {
    id: string | null;
    name: string;
    value: string;
  }) => {
    if (service.id !== null) {
      setShowPopup(true);
      setDeletedService(service);
      return;
    }
    setServices((prev) =>
      prev.filter((prevService) => prevService.name !== service.name),
    );
  };

  const deleteService = () => {
    mutateServiceDelete({
      id: deletedService.id,
      status: 4,
    });
  };

  const handleServiceChange = (
    e: ChangeEvent<HTMLInputElement>,
    service: { name: string; value: string },
  ) => {
    const foundServiceIndex = services.findIndex(
      (s) => s.name === service.name,
    );
    services[foundServiceIndex].value = e.target.value;
    const newServices = [...services];
    setServices(newServices);
  };

  if (error) {
    handleError(error);
  }

  return (
    <>
      {isLoading ? (
        <div className="w-full flex items-center justify-center text-primary h-[calc(100vh-300px)]">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-center space-x-2 mb-5">
            {isEdit ? (
              <>
                <Edit className="text-primary" />
                <Heading size="md">Edit Service Category</Heading>
              </>
            ) : (
              <>
                <Add className="text-primary" />
                <Heading size="md">Create Service Category</Heading>
              </>
            )}
          </div>
          <form
            className="max-w-xl mx-auto"
            onSubmit={handleSubmit(handleCreateService)}
          >
            <Input
              register={register}
              name="title"
              type="text"
              label="Title"
              placeholder="Enter title"
              error={errors.title?.message}
            />
            <Textarea
              register={register}
              name="description"
              label="Description"
              placeholder="Enter description"
              error={errors.description?.message}
            />

            <Input
              trailing={
                progressBarValue !== 0 && (
                  <CircularWithValueLabel value={progressBarValue} />
                )
              }
              register={register}
              name="image"
              type="file"
              label={!isEdit ? "Select Image" : "Edit Image"}
              error={errors[""]?.message}
            />

            {imagePreview && (
              <div className="mb-2 w-full h-52 bg-gray p-2 rounded">
                <img
                  className="w-full h-full overflow-hidden"
                  src={imagePreview}
                  alt="preview"
                />
              </div>
            )}

            {services.map((service, i) => (
              <Input
                key={i}
                value={service.value}
                onChange={(e) => handleServiceChange(e, service)}
                required
                name={service.name.replace(" ", "")}
                type="text"
                label={service.name}
                onTrailingClick={() => handleRemoveService(service)}
                trailing={
                  <DeleteForever className="cursor-pointer text-red-400" />
                }
              />
            ))}

            <Button
              onClick={handleAddService}
              type="button"
              variant="outline"
              className="mb-2"
              leading={Add}
            >
              Add Service
            </Button>

            <input type="checkbox" {...register("isImageRequired")} hidden />

            <Button
              isLoading={isUploading || isPending}
              type="submit"
              size="lg"
              className="w-full mt-5"
            >
              Submit
            </Button>
          </form>
          <Popup
            header="Delete Service"
            body={
              <Text clr="light">
                Are you sure you want to delete this appointment?
              </Text>
            }
            show={showPopup}
            onClose={() => setShowPopup(false)}
            footer={
              <div className="flex justify-center gap-4">
                <Button
                  isLoading={isServiceDeletePending}
                  variant="default"
                  onClick={deleteService}
                >
                  Yes, I'm sure
                </Button>
                <Button variant="outline" onClick={() => setShowPopup(false)}>
                  No, cancel
                </Button>
              </div>
            }
          />
        </>
      )}
    </>
  );
};

export default CreateService;
