import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <div className="relative text-primary">
      <CircularProgress variant="determinate" color="inherit" {...props} />
      <div className="absolute inset-0 flex items-center justify-center">
        <p className="text-sm">{`${Math.round(props.value)}%`}</p>
      </div>
    </div>
  );
}

export default function CircularWithValueLabel(props: { value: number }) {
  return <CircularProgressWithLabel value={props.value} />;
}
