import { z } from "zod";

export const LoginSchema = z.object({
  email: z
    .string({
      required_error: "* Required",
    })
    .email("Must be a valid email")
    .min(1, "* Required"),
  password: z
    .string({
      required_error: "* Required",
    })
    .min(6, "Password too short"),
});

export type LoginFormInputs = z.infer<typeof LoginSchema>;
