import { FC, TextareaHTMLAttributes } from "react";
import { UseFormRegister } from "react-hook-form";
import { cn } from "../../lib/utils";

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  register?: UseFormRegister<any>;
  onTrailingClick?: () => void;
  name: string;
  trailing?: React.JSX.Element;
  label: string;
  error?: string;
}

const Textarea: FC<TextareaProps> = ({
  register,
  name,
  label,
  error,
  trailing,
  onTrailingClick,
  ...props
}) => {
  return (
    <div className="mb-2">
      <label className="block mb-1" htmlFor={name}>
        {label}
      </label>
      <div
        className={cn({
          "flex items-center gap-2": trailing,
        })}
      >
        <textarea
          id={name}
          {...(register ? register(name) : null)}
          {...props}
          className="bg-gray p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 w-full"
        />
        {trailing && (
          <div
            onClick={onTrailingClick}
            className="flex items-center justify-center rounded-md bg-gray self-stretch px-2 cursor-pointer"
          >
            {trailing && trailing}
          </div>
        )}
      </div>
      {error && <span className="text-sm text-red-500">{error}</span>}
    </div>
  );
};

export default Textarea;
