import { Add, MedicalServicesOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleError } from "../api/error-handler";
import { getServiceCategories } from "../api/requests/service";
import Button from "../components/ui/Button";
import Heading from "../components/ui/Heading";
import { formatDate } from "../lib/utils";
import { PageType } from "../types/enum";
import { ServiceCategoryResponse } from "../types/service.types";

interface GridRowDef {
  id: string;
  title: string;
  createdAt: string;
}

const Services = () => {
  let rows: GridRowDef[] = [];

  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const { data, error, isLoading } = useQuery<ServiceCategoryResponse>({
    queryFn: () => getServiceCategories(page, PageType.paged),
    queryKey: ["getServiceCategories", { page }],
  });

  if (error) {
    handleError(error);
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70, flex: 1 },
    { field: "title", headerName: "Title", width: 130, flex: 1 },
    { field: "createdAt", headerName: "Created At", width: 130, flex: 1 },
    {
      field: "",
      headerName: "Action",
      renderCell: ({ row }) => {
        return (
          <Button onClick={() => navigate(`/services/edit/${row.id}`)}>
            Edit
          </Button>
        );
      },
    },
  ];

  if (data) {
    rows = data.serviceCategories.map((serviceCategory) => {
      return {
        id: serviceCategory.id,
        title: serviceCategory.title,
        createdAt: formatDate(serviceCategory.createdAt, "LLL"),
      };
    });
  }

  return (
    <>
      <div className="flex items-center justify-between mb-5">
        <div className="flex items-center space-x-2">
          <MedicalServicesOutlined className="text-primary" />
          <Heading size="md">All Services</Heading>
        </div>
        <Button onClick={() => navigate("/services/create")} leading={Add}>
          Create
        </Button>
      </div>
      {isLoading ? (
        <div className="w-full flex items-center justify-center text-primary h-[calc(100vh-300px)]">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <DataGrid
          rows={rows}
          autoHeight
          columns={columns}
          rowCount={data?.totalItems}
          pagination={true}
          paginationMode="server"
          onPaginationModelChange={(model) => setPage(model.page)}
          initialState={{
            pagination: {
              paginationModel: { page: page, pageSize: data?.pageSize },
            },
          }}
        />
      )}
    </>
  );
};

export default Services;
