import Heading from "../components/ui/Heading";
import { DashboardOutlined, CalendarMonthOutlined } from "@mui/icons-material";
import Card from "../components/ui/Card";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import SearchInput from "../components/ui/Search";
import Select from "../components/ui/Select";
import Button from "../components/ui/Button";
import { useQuery } from "@tanstack/react-query";
import {
  AppointmentResponse,
  AppointmentStatsResponse,
} from "../types/appointment.types";
import { getAppointments, getStats } from "../api/requests/appointment";
import { PageType } from "../types/enum";
import { useState } from "react";
import { handleError } from "../api/error-handler";
import { formatDate } from "../lib/utils";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface GridRowDef {
  id: string;
  fullName: string;
  phoneNumber: string;
  alternativePhoneNumber: string;
  service: string;
  status: string;
  appointmentDate: string;
}

const Dashboard = () => {
  let rows: GridRowDef[] = [];

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchBy, setSearchBy] = useState<string>("phoneNumber");

  const value1Date = new Date();
  const value2Date = new Date();
  value1Date.setHours(0);
  value1Date.setMinutes(0);

  value2Date.setHours(23);
  value2Date.setMinutes(59);
  const value = formatDate(value1Date, "MM/DD/YYYY HH:mm.SSS");
  const value2 = formatDate(value2Date, "MM/DD/YYYY HH:mm.SSS");

  const searchCriteriaList = [
    {
      filterKey: searchBy,
      operation: "cn",
      value: searchQuery,
    },
    {
      filterKey: "appointmentDate",
      operation: "bt",
      value,
      value2,
    },
  ];

  const { data, error, isLoading, refetch } = useQuery<AppointmentResponse>({
    queryFn: () => getAppointments(page, PageType.paged, searchCriteriaList),
    queryKey: ["getTodaysAppointments", { page }],
  });

  const {
    data: stats,
    error: errorStats,
    isLoading: isLoadingStats,
  } = useQuery<AppointmentStatsResponse>({
    queryFn: () => getStats(),
    queryKey: ["getStats"],
  });

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100 },

    {
      field: "fullName",
      headerName: "Full name",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "alternativePhoneNumber",
      headerName: "Alternative phone number",
      flex: 1,
    },
    {
      field: "service",
      headerName: "Service",
      flex: 1,
    },
    {
      field: "appointmentDate",
      headerName: "Appointment date",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",

      renderCell: ({ row }) => {
        return (
          <Button onClick={() => navigate(`appointment-detail/${row.id}`)}>
            Detail
          </Button>
        );
      },
    },
  ];

  if (error) {
    handleError(error);
  }
  if (errorStats) {
    handleError(errorStats);
  }

  if (data) {
    rows = data.appointments.map((appointment) => {
      return {
        id: appointment.id,
        fullName: appointment.fullName,
        phoneNumber: appointment.phoneNumber,
        alternativePhoneNumber: appointment.alternativePhoneNumber,
        service: appointment.serviceCategory.title,
        status: appointment.status,
        appointmentDate: formatDate(appointment.appointmentDate, "LLL"),
      };
    });
  }

  return (
    <>
      <div className="flex items-center space-x-2 mb-5">
        <DashboardOutlined className="text-primary" />
        <Heading size="md">Overview</Heading>
      </div>
      <div className="flex justify-between space-x-5 mb-5">
        <Card
          loading={isLoadingStats}
          title="New Appointments"
          body={stats?.totalNewAppointments!}
          icon={CalendarMonthOutlined}
          bg="bg-gray"
        />
        <Card
          loading={isLoadingStats}
          title="Total Appointments"
          body={stats?.totalAppointments!}
          icon={CalendarMonthOutlined}
          bg="bg-gray"
        />
      </div>
      <div className="flex items-center justify-between mb-5">
        <div className="flex items-center space-x-2">
          <CalendarMonthOutlined className="text-primary" />
          <Heading size="md">Todays Appointments</Heading>
        </div>
        <div className="flex items-center space-x-2">
          <SearchInput
            value={searchQuery}
            placeholder="Search"
            handleSearch={() => refetch()}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Select
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
            style={{ marginBottom: 0 }}
            placeholder="Search By"
            options={[
              { label: "Phone Number", value: "phoneNumber" },
              { label: "Full Name", value: "fullName" },
              {
                label: "Alternative Phone Number",
                value: "alternativePhoneNumber",
              },
            ]}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="w-full flex items-center justify-center text-primary h-[calc(100vh-300px)]">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          rowCount={data?.totalItems}
          initialState={{
            pagination: {
              paginationModel: { page: page, pageSize: data?.pageSize },
            },
          }}
          pagination={true}
          paginationMode="server"
          onPaginationModelChange={(model) => setPage(model.page)}
        />
      )}
    </>
  );
};

export default Dashboard;
