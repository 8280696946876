import { ServiceCategoryResponse } from "./../../types/service.types";
import { endpoints } from "../endpoints";
import api from "..";

const createServiceCategory = async (data: {
  title: string;
  imageUrl: string;
  services: string[];
}): Promise<ServiceCategoryResponse> => {
  const response = await api.post(endpoints.createServiceCategory, data);
  return response.data;
};

const updateServiceCategory = async (
  id: string,
  data: {
    title: string;
    imageUrl: string;
    services: string[];
  },
): Promise<ServiceCategoryResponse> => {
  const response = await api.post(
    `${endpoints.updateServiceCategory}/${id}`,
    data,
  );
  return response.data;
};

const updateServiceStatus = async (data: {
  id: string;
  status: number;
}): Promise<ServiceCategoryResponse> => {
  const response = await api.post(
    `${endpoints.updateServiceStatus}/${data.id}`,
    { status: data.status },
  );
  return response.data;
};

const getServiceCategories = async (
  page: number,
  pagingType: string,
): Promise<ServiceCategoryResponse> => {
  const response = await api.get(endpoints.getServiceCategories, {
    params: {
      page,
      pagingType,
    },
  });
  return response.data;
};

const getServiceCategory = async (
  id: string,
): Promise<ServiceCategoryResponse> => {
  const response = await api.get(`${endpoints.getServiceCategory}/${id}`);
  return response.data;
};

export {
  getServiceCategories,
  createServiceCategory,
  getServiceCategory,
  updateServiceCategory,
  updateServiceStatus,
};
