import toast from "react-hot-toast";

export const handleError = (error: any) => {
  if (
    error.response !== null &&
    error.response?.data?.readableErrorMessages &&
    error.response?.data?.readableErrorMessages.length > 0
  ) {
    const errors: string[] = error.response.data.readableErrorMessages;
    errors.forEach((error) => {
      toast.error(error);
    });
  } else {
    toast.error(error.message);
  }
};
