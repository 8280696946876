import { Add, Edit } from "@mui/icons-material";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Heading from "../components/ui/Heading";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CreateAdminFormInputs,
  CreateAdminSchema,
} from "../validations/CreateAdminSchema";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "../components/ui/Input";
import Select from "../components/ui/Select";
import { AdminStatus, PageType } from "../types/enum";
import Button from "../components/ui/Button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AdminsResponse } from "../types/user.types";
import { handleError } from "../api/error-handler";
import toast from "react-hot-toast";
import { createAdmin, getAdmins, updateAdmin } from "../api/requests/admins";
import { CircularProgress } from "@mui/material";

interface CreateServiceProps {
  edit?: boolean;
}

const CreateAdmin: FC<CreateServiceProps> = ({ edit }) => {
  const { id } = useParams();

  const isEdit = id && edit;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CreateAdminFormInputs>({
    resolver: zodResolver(CreateAdminSchema),
  });

  const searchCriteriaList = [
    {
      filterKey: "userType",
      operation: "eq",
      value: "0",
    },
    {
      filterKey: "id",
      operation: "eq",
      value: id,
    },
  ];

  const { data, error, isLoading } = useQuery<AdminsResponse>({
    queryFn: () => getAdmins(0, PageType.paged, searchCriteriaList),
    queryKey: ["getAdmins"],
    enabled: isEdit ? true : false,
  });

  const { mutate, isPending } = useMutation<
    AdminsResponse,
    unknown,
    CreateAdminFormInputs
  >({
    mutationFn: (data) => (isEdit ? updateAdmin(id, data) : createAdmin(data)),
    onSuccess: (data) => {
      toast.success(`Admin ${isEdit ? "updated" : "created"}`);
      reset();
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const handleCreateAdmin: SubmitHandler<CreateAdminFormInputs> = async (
    data,
  ) => {
    mutate({
      fullName: data.fullName,
      email: data.email,
      password: data.password !== "" ? data.password : undefined,
      status: data.status,
    });
  };

  useEffect(() => {
    if (data) {
      let currentStatus: string = "";
      for (const status in AdminStatus) {
        if (AdminStatus[status] === data.users[0].status) {
          currentStatus = status;
          break;
        }
      }
      setValue("fullName", data.users[0].fullName);
      setValue("email", data.users[0].email);
      setValue("status", currentStatus);
    }
  }, [data, setValue]);

  if (error) {
    handleError(error);
  }

  return (
    <>
      {isLoading ? (
        <div className="w-full flex items-center justify-center text-primary h-[calc(100vh-300px)]">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-center space-x-2 mb-5">
            {isEdit ? (
              <>
                <Edit className="text-primary" />
                <Heading size="md">Edit Admin</Heading>
              </>
            ) : (
              <>
                <Add className="text-primary" />
                <Heading size="md">Create Admin</Heading>
              </>
            )}
          </div>
          <form
            className="max-w-xl mx-auto"
            onSubmit={handleSubmit(handleCreateAdmin)}
          >
            <Input
              register={register}
              name="fullName"
              type="text"
              label="Full Name"
              placeholder="Enter full name"
              error={errors.fullName?.message}
            />
            <Input
              register={register}
              name="email"
              type="email"
              label="Email"
              placeholder="Enter email"
              error={errors.email?.message}
            />
            <Input
              register={register}
              name="password"
              type="password"
              label="Password"
              placeholder="Enter password"
              error={errors.password?.message}
            />
            <Select
              register={register}
              name="status"
              label="Status"
              placeholder="Status"
              options={[
                { label: "Active", value: AdminStatus.ACTIVE.toString() },
                {
                  label: "Inactive",
                  value: AdminStatus.INACTIVE.toString(),
                },
              ]}
              error={errors.status?.message}
            />
            <Button
              isLoading={isPending}
              type="submit"
              size="lg"
              className="w-full mt-5"
            >
              Submit
            </Button>
          </form>
        </>
      )}
    </>
  );
};

export default CreateAdmin;
