import Sidebar from "./components/Sidebar";
import { Outlet } from "react-router";
import Navbar from "./components/Navbar";
import { useEffect } from "react";
import useRealtime from "./hooks/useRealtime";
import { Appointment } from "./types/appointment.types";
import { pushNotification } from "./lib/utils";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  useRealtime({
    topic: "/topic/appointment",
    callback: (param: string) => {
      const appointment: Appointment = JSON.parse(param).appointment;

      pushNotification(
        "New Appointment",
        () => navigate(`/appointment-detail/${appointment.id}`),
        {
          body: appointment.serviceCategory.title,
          requireInteraction: false,
          icon: "https://res.cloudinary.com/dic4mtiik/image/upload/v1721832650/e6688ea5-71b4-4703-8892-40f4588e487e-removebg-preview_pg0uhf.png",
        },
      );
    },
  });

  useEffect(() => {
    Notification.requestPermission();
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <main className="pl-64 w-full">
        <Navbar />
        <div className="p-4">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default App;
