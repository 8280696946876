import { toast } from "react-hot-toast";
import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";
import { ACCESS_TOKEN } from "./constants";

export const WEBSOCKET_URL = process.env.REACT_APP_API_SOCKET_ENDPOINT;

const api: AxiosInstance = axios.create({
  // baseURL: "https://api.lebeza.org/api/v1/",
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  // baseURL: "http://192.168.1.4:8080/api/v1/",
});

api.interceptors.request.use(
  (config) => {
    const token = Cookies.get(ACCESS_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response?.status === 401) {
      toast.error("Unauthorized");
      Cookies.remove(ACCESS_TOKEN);
    } else {
      return Promise.reject(err);
    }
  },
);

export default api;
