import { forwardRef, ReactNode } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { HTMLAttributes } from "react";
import { cn } from "../../lib/utils";

const headingVariants = cva(
  "text-black text-left font-bold leading-tight tracking-tighter",
  {
    variants: {
      size: {
        default: "text-xl",
        md: "text-2xl",
        lg: "text-3xl ",
      },
      clr: {
        default: "text-black",
        primary: "text-primary",
      },
    },
    defaultVariants: {
      size: "default",
      clr: "default",
    },
  },
);

interface HeadingProps
  extends HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof headingVariants> {
  children: ReactNode;
}

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ size, clr, children, className, ...props }, ref) => {
    return (
      <h1
        ref={ref}
        {...props}
        className={cn(headingVariants({ size, clr, className }))}
      >
        {children}
      </h1>
    );
  },
);

Heading.displayName = "Heading";

export default Heading;
