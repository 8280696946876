import { endpoints } from "./../endpoints";
import {
  AppointmentResponse,
  AppointmentStatsResponse,
} from "./../../types/appointment.types";
import api from "..";

const getAppointments = async (
  page: number,
  pagingType: string,
  searchCriteriaList: any,
): Promise<AppointmentResponse> => {
  const response = await api.post(endpoints.getAppointments, {
    dataOption: "all",
    searchCriteriaList,
    page,
    pagingType,
  });
  return response.data;
};

const updateAppointmentStatus = async (data: {
  id: string;
  status: number;
}): Promise<AppointmentResponse> => {
  const response = await api.post(
    `${endpoints.updateAppointmentStatus}/${data.id}`,
    {
      status: data.status,
    },
  );
  return response.data;
};

const getStats = async (): Promise<AppointmentStatsResponse> => {
  const response = await await api.get(endpoints.getStat);
  return response.data;
};

// const { data, isLoading, error, isError } = useQuery({
//   queryFn: getStats,
// });

// if (error && isError) {
//   handleError(error);
// }

export { getAppointments, updateAppointmentStatus, getStats };
