import { Client } from "@stomp/stompjs";
import { useEffect } from "react";
import { WEBSOCKET_URL } from "../api";

type useRealtimeParams = {
  brokerURL?: string;
  topic: string;
  callback: (params: any) => void;
};

const useRealtime = ({ brokerURL, callback, topic }: useRealtimeParams) => {
  useEffect(() => {
    const client = new Client({
      brokerURL: brokerURL ?? WEBSOCKET_URL,
      connectHeaders: {},
      onConnect: () => {
        console.log("Connected");
        client.subscribe(topic, (message: any) => {
          if (message.body !== null) {
            return callback(message.body);
          }
        });
      },
      onStompError: (err: any) => console.log(err),
    });
    client.activate();
    return () => {
      client.deactivate();
    };
  }, [brokerURL, callback, topic]);
};

export default useRealtime;
