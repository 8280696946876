import Button from "./ui/Button";
import { LogoutOutlined } from "@mui/icons-material";
import Heading from "./ui/Heading";
import { sidebarItems } from "./Sidebar";
import { useLocation, useNavigate } from "react-router";
import Cookies from "js-cookie";
import { ACCESS_TOKEN } from "../api/constants";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navTitle = sidebarItems.find((item) => item.link === location.pathname);

  const handleLogout = () => {
    Cookies.remove(ACCESS_TOKEN);
    navigate("/login");
  };

  return (
    <nav className="sticky top-0 z-10 flex items-center justify-between border-b border-neutral-200 bg-white px-4 py-2">
      <Heading clr="primary" size="md">
        {navTitle?.label}
      </Heading>
      <Button onClick={handleLogout} leading={LogoutOutlined} variant="outline">
        Logout
      </Button>
    </nav>
  );
};

export default Navbar;
