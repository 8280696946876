import { Add, AdminPanelSettingsOutlined } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Button from "../components/ui/Button";
import Heading from "../components/ui/Heading";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { AdminStatus, PageType } from "../types/enum";
import { AdminsResponse } from "../types/user.types";
import { useQuery } from "@tanstack/react-query";
import { getAdmins } from "../api/requests/admins";
import Select from "../components/ui/Select";
import { CircularProgress } from "@mui/material";
import { handleError } from "../api/error-handler";

const Admins = () => {
  let rows: any = [];

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [filterStatus, setFilterStatus] = useState<string>(
    AdminStatus.ACTIVE.toString(),
  );

  const searchCriteriaList = [
    {
      filterKey: "userType",
      operation: "eq",
      value: "0",
    },
    {
      filterKey: "status",
      operation: "eq",
      value: parseInt(filterStatus),
    },
  ];

  const { data, error, isLoading } = useQuery<AdminsResponse>({
    queryFn: () => getAdmins(page, PageType.paged, searchCriteriaList),
    queryKey: ["getAdmins", { page }, { filterStatus }],
  });

  if (data) {
    rows = data.users.map((user) => {
      return {
        id: user.id,
        fullName: user.fullName,
        email: user.email,
        status: user.status,
      };
    });
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "fullName", headerName: "Full Name", flex: 1 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "",
      headerName: "Action",
      renderCell: ({ row }) => {
        return (
          <Button onClick={() => navigate(`/admins/edit/${row.id}`)}>
            Edit
          </Button>
        );
      },
    },
  ];

  if (error) {
    handleError(error);
  }

  return (
    <>
      <div className="flex items-center justify-between mb-5">
        <div className="flex items-center space-x-2">
          <AdminPanelSettingsOutlined className="text-primary" />
          <Heading size="md">Admin Users</Heading>
        </div>
        <div className="flex items-center space-x-2">
          <Select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            style={{ marginBottom: 0 }}
            placeholder="Status"
            options={[
              { label: "Active", value: AdminStatus.ACTIVE.toString() },
              {
                label: "Inactive",
                value: AdminStatus.INACTIVE.toString(),
              },
            ]}
          />
          <Button onClick={() => navigate("/admins/create")} leading={Add}>
            Create
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div className="w-full flex items-center justify-center text-primary h-[calc(100vh-300px)]">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          rowCount={data?.totalItems}
          initialState={{
            pagination: {
              paginationModel: { page: page, pageSize: data?.pageSize },
            },
          }}
          pagination={true}
          paginationMode="server"
          onPaginationModelChange={(model) => setPage(model.page)}
        />
      )}
    </>
  );
};

export default Admins;
