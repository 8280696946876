import { z } from "zod";

export const CreateAdminSchema = z.object({
  fullName: z
    .string({
      required_error: "* Required",
    })
    .min(1, "* Required"),
  email: z
    .string({
      required_error: "* Required",
    })
    .email("Enter valid email")
    .min(1, "* Required"),
  password: z.string().optional(),
  status: z
    .string({
      required_error: "* Required",
    })
    .min(1, "* Required"),
});

export type CreateAdminFormInputs = z.infer<typeof CreateAdminSchema>;
